import React, { useState, useEffect, useContext } from 'react';
import { CalendarContext, SET_FIRST_DAY } from '../context';
import {
  formatTime,
  toISODateString,
  isEven,
  getTomorrow,
} from '../../../utils';
import CalendarCell from '../CalendarCell';
import { days, initialDaysOff } from '../../../constants';

export function useTable({
  startDay, // Obavezno, 'today', 'tomorrow', Date() object ili string sa startnim danom
  data, // Obavezno, rezervirani termini
  terminFrom = 8, // Od koliko sati kreću termini, sati
  terminTo = 19, // do koliko sati su termini, sati
  duration = 0.5, // trajanje termina, sati
  daysOff = initialDaysOff, // neradni dani, 0 ponedjeljak - 6 nedjelja, even i odd (datumi) array
}) {
  const {
    state: { firstDay },
    dispatch,
  } = useContext(CalendarContext);
  const [today, setToday] = useState();
  const [initialFirstDay, setInitialFirstDay] = useState();
  const [initialLastDay, setInitialLastDay] = useState();
  const [header, setHeader] = useState([]);
  const [rows, setRows] = useState([]);

  // ********************************************
  // ***** Setira today i firstDay
  useEffect(() => {
    const lastDay = new Date();
    let date;

    if (startDay === 'tomorrow') {
      date = getTomorrow();
    } else if (startDay === 'today') {
      date = new Date();
    } else {
      date = new Date(startDay);
    }

    setToday(new Date());
    dispatch({ type: SET_FIRST_DAY, payload: date });

    lastDay.setDate(date.getDate() + 6);
    setInitialFirstDay(date);
    setInitialLastDay(lastDay);
  }, [dispatch, startDay]);

  // ********************************************
  // ***** Setira header
  useEffect(() => {
    if (firstDay === undefined || today === undefined) {
      return;
    }

    const arr = [];
    const dateIterator = new Date(firstDay);

    arr.push(<CalendarCell key={0} type='colHeader' />);
    for (let col = 1; col < 8; col++) {
      arr.push(
        // isNow - ako je iterator dana u tjednu jednak today
        <CalendarCell
          key={col}
          type='colHeader'
          isNow={toISODateString(dateIterator) === toISODateString(today)}
        >
          <div className='day'>{days[dateIterator.getDay()]}</div>
          <div className='date'>{`${dateIterator.getDate()}.`}</div>
        </CalendarCell>
      );

      dateIterator.setDate(dateIterator.getDate() + 1);
    }

    setHeader([...arr]);
  }, [firstDay, today]);

  // ********************************************
  // ***** Setira rows
  useEffect(() => {
    if (firstDay === undefined || today === undefined) {
      return;
    }

    const arr = [];
    const localTime = today.toLocaleTimeString('hr-HR').substr(0, 5);

    // vanjska for petlja, time - termini
    for (let time = terminFrom; time < terminTo; time += duration) {
      const arrCol = [];
      const formatedTime = formatTime(time);
      const formatedNextTime = formatTime(time + duration);
      const dateIterator = new Date(firstDay);

      arrCol.push(
        // isNow - ako je localTime u granicama termina
        <CalendarCell
          key={0}
          type='rowHeader'
          isNow={formatedTime <= localTime && formatedNextTime > localTime}
        >
          {formatedTime}
        </CalendarCell>
      );

      // unutarnja for petlja, col - dani u tjednu
      for (let col = 1; col < 8; col++) {
        const dateIteratorGetDay = dateIterator.getDay();
        let isOutOfScope = false;
        let termType = 'free';

        // 1. provjera isInactive termin
        // ako je daysOff length 7 pretpostavljamo da je postavljen
        // ako je even provjeravamo postavke za parne dane, u suprotnom za neparne
        if (daysOff.length === 7) {
          if (isEven(dateIterator.getDate())) {
            for (
              let i = 0;
              i < daysOff[dateIteratorGetDay].even.length / 2;
              i++
            ) {
              if (
                daysOff[dateIteratorGetDay].even[i * 2] <= formatedTime &&
                daysOff[dateIteratorGetDay].even[i * 2 + 1] > formatedTime
              ) {
                termType = 'inactive';
              }
            }
          } else {
            for (
              let i = 0;
              i < daysOff[dateIteratorGetDay].odd.length / 2;
              i++
            ) {
              if (
                daysOff[dateIteratorGetDay].odd[i * 2] <= formatedTime &&
                daysOff[dateIteratorGetDay].odd[i * 2 + 1] > formatedTime
              ) {
                termType = 'inactive';
              }
            }
          }
        }

        // ***** Napredna isOutOfScope provjera
        // if (toISODateString(dateIterator) < toISODateString(today)) {
        //   // 1. provjera isOutOfScope termina
        //   // ako je dan prošao
        //   isOutOfScope = true;
        // } else if (
        //   // 2. provjera isOutOfScope termina (ako već nije postavljen)
        //   // ako je termin prošao
        //   toISODateString(dateIterator) === toISODateString(today) &&
        //   formatedTime < localTime
        // ) {
        //   isOutOfScope = true;
        // }

        // ***** Jednostavna isOutOfScope provjera (uvjetovana zadatkom)
        // 1. provjera isOutOfScope termina
        // ako je termin van tjedna
        if (
          toISODateString(dateIterator) < toISODateString(initialFirstDay) ||
          toISODateString(dateIterator) > toISODateString(initialLastDay)
        ) {
          isOutOfScope = true;
        }

        const cellId = `${toISODateString(dateIterator)}${formatedTime}`;
        const found = data.find((element) => element.id === cellId);
        if (found) {
          termType = found.user;
        }
        arrCol.push(
          <CalendarCell
            key={col}
            isOutOfScope={isOutOfScope}
            type='cell'
            id={cellId}
            termType={termType}
          />
        );

        dateIterator.setDate(dateIterator.getDate() + 1);
      }

      arr.push(arrCol);
    }

    setRows([...arr]);
  }, [
    daysOff,
    duration,
    terminFrom,
    terminTo,
    firstDay,
    today,
    data,
    initialFirstDay,
    initialLastDay,
  ]);

  return {
    header,
    rows,
  };
}
