import React from 'react';
import CalendarContextProvider from './context';
import CalendarHeader from './CalendarHeader';
import CalendarTable from './CalendarTable';

import './index.css';

function Calendar({ ...props }) {
  return (
    <CalendarContextProvider>
      <CalendarHeader />
      <CalendarTable {...props} />
    </CalendarContextProvider>
  );
}

export default Calendar;
