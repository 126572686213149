import React, { useContext } from 'react';
import { MainContext, ADD_TERM, CANCEL_TERM } from './context';
import { Calendar } from './components';

function App() {
  const {
    state: { data },
    dispatch,
  } = useContext(MainContext);

  const handleNewTerm = (cellId) => {
    dispatch({ type: ADD_TERM, payload: { id: cellId, user: 'me' } });
  };

  const handleCancelTerm = (cellId) => {
    dispatch({ type: CANCEL_TERM, payload: { id: cellId, user: 'me' } });
  };

  return (
    <Calendar
      startDay='tomorrow'
      onNewTerm={handleNewTerm}
      onCancelTerm={handleCancelTerm}
      data={data}
    />
  );
}

export default App;
