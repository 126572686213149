import React, { createContext, useReducer } from 'react';
import { getTomorrow, formatTime, isEven, toISODateString } from '../utils';
import { initialDaysOff } from '../constants';

export const ADD_TERM = 'ADD_TERM';
export const CANCEL_TERM = 'CANCEL_TERM';

// ********************************************
// ***** Je li prijeđen dnevni limit
function isDayLimitExceeded(id, data) {
  const dayString = id.substr(0, 10);
  if (
    data.find((el) => el.id.substr(0, 10) === dayString && el.user === 'me')
  ) {
    return true;
  }
  return false;
}

// ********************************************
// ***** Je li prijeđen tjedni limit
function isWeekLimitExceeded(data) {
  if (data.filter((el) => el.user === 'me').length > 1) {
    return true;
  }
  return false;
}

// ********************************************
// ***** reducer
function reducer(state, action) {
  switch (action.type) {
    case ADD_TERM:
      if (isDayLimitExceeded(action.payload.id, state.data)) {
        alert('U jednom danu možete imati samo jednu rezervaciju termina!');
        return state;
      }
      if (isWeekLimitExceeded(state.data)) {
        alert('U jednom tjednu možete imati samo dvije rezervacije termina!');
        return state;
      }
      return { ...state, data: [...state.data, action.payload] };
    case CANCEL_TERM:
      const index = state.data.findIndex((el) => el.id === action.payload.id);
      return {
        ...state,
        data: [...state.data.slice(0, index), ...state.data.slice(index + 1)],
      };

    default:
      // ne želimo action koji nismo definirali
      throw new Error();
  }
}

const initialState = {
  data: [],
};

// **********************************************
// ***** Immediately-invoked Function Expression
// ***** IIFE za postavljanje fake termina
(() => {
  const dateIterator = getTomorrow();
  const arr = [];

  // Slobodni termini u arr
  for (let i = 0; i < 7; i++) {
    const dIGetDay = dateIterator.getDay();
    const isEvenDate = isEven(dateIterator.getDate());

    for (let time = 8; time < 19; time += 0.5) {
      const formatedTime = formatTime(time);
      let isInactive = false;

      if (isEvenDate) {
        for (let i = 0; i < initialDaysOff[dIGetDay].even.length / 2; i++) {
          if (
            initialDaysOff[dIGetDay].even[i * 2] <= formatedTime &&
            initialDaysOff[dIGetDay].even[i * 2 + 1] > formatedTime
          ) {
            isInactive = true;
          }
        }
      } else {
        for (let i = 0; i < initialDaysOff[dIGetDay].odd.length / 2; i++) {
          if (
            initialDaysOff[dIGetDay].odd[i * 2] <= formatedTime &&
            initialDaysOff[dIGetDay].odd[i * 2 + 1] > formatedTime
          ) {
            isInactive = true;
          }
        }
      }

      if (!isInactive) {
        arr.push(`${toISODateString(dateIterator)}${formatedTime}`);
      }
    }
    dateIterator.setDate(dateIterator.getDate() + 1);
  }

  // Postavljanje 15 (ili manje ako je manje slobodnih termina) fake termina
  let arrLength = arr.length;
  for (let i = 0; i < Math.min(arrLength, 15); i++) {
    const index = Math.floor(Math.random() * (arrLength - i));

    initialState.data.push({ id: arr[index], user: 'other' });
    arr.splice(index, 1);
  }
})();
// **********************************************

export const MainContext = createContext(initialState);

function MainContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      {children}
    </MainContext.Provider>
  );
}

export default MainContextProvider;
