import React, { useContext } from 'react';
import { CalendarContext, CELL_FLUSH } from './context';
import { Modal } from '../';
import { useTable } from './hooks';

function CalendarTable({ onNewTerm, onCancelTerm, ...otherProps }) {
  const {
    state: { cell },
    dispatch,
  } = useContext(CalendarContext);
  const { header, rows } = useTable({ ...otherProps });

  return (
    <>
      <div className='calendar-table'>
        <table>
          <thead>
            <tr>{header}</tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              return <tr key={index}>{row}</tr>;
            })}
          </tbody>
        </table>
      </div>

      <Modal
        isShow={cell.id !== ''}
        ok
        cancel
        handleClose={(button) => {
          if (button === 'ok') {
            if (cell.termType === 'free') {
              onNewTerm(cell.id);
            } else if (cell.termType === 'me') {
              onCancelTerm(cell.id);
            }
          }
          dispatch({ type: CELL_FLUSH });
        }}
      >
        <p className='title'>{`${
          cell.termType === 'me' ? 'Otkažite' : 'Potvrdite'
        } termin`}</p>
        <p>
          {`Želite li ${
            cell.termType === 'me' ? 'otkazati' : 'rezervirati'
          } termin`}
          {` ${cell.id.substr(8, 2)}.${cell.id.substr(5, 2)}.`}
          {`${cell.id.substr(0, 4)}. u ${cell.id.substr(10, 5)}`}?
        </p>
      </Modal>
    </>
  );
}

export default CalendarTable;
