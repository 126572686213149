const days = [
  'Nedjelja',
  'Ponedjeljak',
  'Utorak',
  'Srijeda',
  'Četvrtak',
  'Petak',
  'Subota',
];

const initialDaysOff = [
  {
    // nedjelja
    even: ['08:00', '19:00'],
    odd: ['08:00', '19:00'],
  },
  {
    // ponedjeljak
    even: ['11:00', '11:30', '14:00', '19:00'],
    odd: ['08:00', '13:00', '16:00', '16:30'],
  },
  {
    // utorak
    even: ['11:00', '11:30', '14:00', '19:00'],
    odd: ['08:00', '13:00', '16:00', '16:30'],
  },
  {
    //srijeda
    even: ['11:00', '11:30', '14:00', '19:00'],
    odd: ['08:00', '13:00', '16:00', '16:30'],
  },
  {
    // četvrtak
    even: ['11:00', '11:30', '14:00', '19:00'],
    odd: ['08:00', '13:00', '16:00', '16:30'],
  },
  {
    // petak
    even: ['11:00', '11:30', '14:00', '19:00'],
    odd: ['08:00', '13:00', '16:00', '16:30'],
  },
  {
    // subota
    even: ['11:00', '11:30', '14:00', '19:00'],
    odd: ['08:00', '19:00'],
  },
];

export { days, initialDaysOff };
